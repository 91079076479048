<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/landing-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Dashboard Name <strong>*</strong></label>
                      <input v-model="formData.name" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Language Name <strong>*</strong></label>
                      <input v-model="formData.native_name" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Language Short Name <strong>*</strong></label>
                      <input v-model="formData.locale" type="text" class="form-control" required />
                    </div>
                  </div>

                  <div class="border-top col-6">
                    <label for="">Language Image </label>
                    <input type="file" class="form-control" @change="fileChange('langImg', $event)" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <json-editor :initial-json="initialJson"></json-editor>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button type="button" class="btn btn-danger" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button type="submit" @click="save()" class="btn btn-success ms-1" :disabled="createSend == 1 ? true : false">
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import JSONEditor from "jsoneditor";
import "../../../assets/css/jsoneditor.css";

export default {
  page: {
    title: "Language Update",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      initialJson: {
      },
      title: "Language Update",
      items: [
        {
          text: "Language",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        name: "",
        locale: "",
        native_name: "",
        langImg: "",
        langJson: "",
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    const container = this.$el;
    const options = {};
    this.editor = new JSONEditor(container, options);
    this.editor.set(this.initialJson);
    this.FindLp();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    async FindLp() {
      await API.Get(API.LanguagePage.UpdateList + this.$route.params.id).then(
        (response) => {
          if (response.data.status == "success") {
            this.formData.name = response.data.order.name;
            this.formData.native_name = response.data.order.native_name;
            this.formData.locale = response.data.order.locale;

            const data= JSON.parse(response.data.order.json);
            
            this.editor.set(data);

          }
        }
      );
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });
      _formData.append("langJson", JSON.stringify(this.editor.get()));

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.LanguagePage.Update + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/language");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}

.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
